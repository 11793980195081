/* This is the core CSS of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
  if greater that the natural height of the tooltip, will be enforced
  in browsers that support display:flex */
  display: flex;
  pointer-events: none;
  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
  and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;
  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  -ms-transition-property: opacity;
  transition-property: opacity;
}
.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* grow */

.tooltipster-grow {
  -webkit-transform: scale(0,0);
  -moz-transform: scale(0,0);
  -o-transform: scale(0,0);
  -ms-transform: scale(0,0);
  transform: scale(0,0);
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-backface-visibility: hidden;
}
.tooltipster-grow.tooltipster-show {
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  -o-transform: scale(1,1);
  -ms-transform: scale(1,1);
  transform: scale(1,1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}

/* swing */

.tooltipster-swing {
  opacity: 0;
  -webkit-transform: rotateZ(4deg);
  -moz-transform: rotateZ(4deg);
  -o-transform: rotateZ(4deg);
  -ms-transform: rotateZ(4deg);
  transform: rotateZ(4deg);
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
}
.tooltipster-swing.tooltipster-show {
  opacity: 1;
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  -o-transform: rotateZ(0deg);
  -ms-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 1);
  -webkit-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -moz-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -ms-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  -o-transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
  transition-timing-function: cubic-bezier(0.230, 0.635, 0.495, 2.4);
}

/* fall */

.tooltipster-fall {
  -webkit-transition-property: top;
  -moz-transition-property: top;
  -o-transition-property: top;
  -ms-transition-property: top;
  transition-property: top;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-fall.tooltipster-initial {
  top: 0 !important;
}
.tooltipster-fall.tooltipster-show {
}
.tooltipster-fall.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  top: 0 !important;
  opacity: 0;
}

/* slide */

.tooltipster-slide {
  -webkit-transition-property: left;
  -moz-transition-property: left;
  -o-transition-property: left;
  -ms-transition-property: left;
  transition-property: left;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -moz-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -ms-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  -o-transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.15);
}
.tooltipster-slide.tooltipster-initial {
  left: -40px !important;
}
.tooltipster-slide.tooltipster-show {
}
.tooltipster-slide.tooltipster-dying {
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  -ms-transition-property: all;
  transition-property: all;
  left: 0 !important;
  opacity: 0;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* rotate */

@keyframes tooltipster-rotating {
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(0);
  }
}

.tooltipster-update-rotate {
  animation: tooltipster-rotating 600ms;
}

/* scale */

@keyframes tooltipster-scaling {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tooltipster-update-scale {
  animation: tooltipster-scaling 600ms;
}
